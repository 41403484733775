import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import {
  ListItem,
  ListItemText,
  Checkbox,
  Typography,
  IconButton,
  Grid,
  List,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';
import RoundedButton from '../../../components/RoundedButton';
import api from '../../../services/api';
import useAlert from '../../../hooks/useAlert';

const useStyles = makeStyles(() => ({
  customButtonDefault: {
    marginLeft: '1em',
    marginTop: '5px',
  },
  divLoading: {
    width: 'auto',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '30px',
  },
}));

export default ({
  filter,
  sectors,
  handleChangeFilter,
  localStorageName,
  showSectorSection = true,
  showTypeSection = true,
}) => {
  const classes = useStyles();
  const setAlert = useAlert();
  const [open, setOpen] = useState(false);
  const [forceRender, setForceRender] = useState(false);
  const [attendants, setAttendants] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(false);
  const [verifyOpt, setVerifyOpt] = useState(false);
  const selectedFilters = useRef({
    sector: [],
    attendant: [],
    type: [],
    status: [],
    read: [],
    order: 1,
    page: 1,
    name: '',
  });

  const availableStatus = [
    {
      id: 1,
      description: 'Todos',
      parameter: null,
    },
    {
      id: 2,
      description: 'Aguardando Finalização',
      parameter: {
        waitClose: true,
      },
    },
    {
      id: 4,
      description: 'Em andamento',
      parameter: {
        inProgress: true,
      },
    },
    {
      id: 5,
      description: 'Finalizado',
      parameter: {
        closed: true,
      },
    },
  ];

  const availableTypeChat = [
    {
      id: 1,
      description: 'Todos',
      parameter: {
        parameter: null,
      },
    },
    {
      id: 2,
      description: 'Autorização',
      parameter: {
        authorization: true,
      },
    },
    {
      id: 3,
      description: 'Mensagem',
      parameter: {
        message: true,
      },
    },
    {
      id: 4,
      description: 'Mensagem lote',
      parameter: {
        messageInBatch: true,
      },
    },
  ];

  const availableSituations = [
    {
      id: 1,
      description: 'Mais recente',
      parameter: {
        order: 1,
      },
    },
    {
      id: 2,
      description: 'Ordem alfabética',
      parameter: {
        order: 2,
      },
    },
  ];

  const availableReadOptions = [
    {
      id: 1,
      description: 'Todos',
      parameter: null,
    },
    {
      id: 2,
      description: 'Lido',
      parameter: {
        read: true,
      },
    },
    {
      id: 3,
      description: 'Não lido',
      parameter: {
        notRead: true,
      },
    },
  ];

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      api
        .get('/chat/getAttendants')
        .then((response) => {
          const params = {
            page: 1,
            ids: response.data,
            limit: 100,
          };

          if (response.data.length > 0) {
            api
              .get('users/getUsersByIds', { params })
              .then((resp) => {
                setAttendants(resp.data.data);
                setIsLoading(false);
              })
              .catch(() => {
                setAlert({
                  isOpen: true,
                  type: 'error',
                  message:
                    'Ocorreu um problema inesperado ao buscar os atendentes!',
                });
                setIsLoading(false);
              });
          } else {
            setIsLoading(false);
          }
        })
        .catch(() => {
          setAlert({
            isOpen: true,
            type: 'error',
            message: 'Ocorreu um problema inesperado ao buscar os atendentes!',
          });
          setIsLoading(false);
        });

      Object.entries(filter).forEach(([key], idx) => {
        if (key === 'idSector') {
          selectedFilters.current.sector = filter.idSector;
        }

        if (key === 'attendants') {
          selectedFilters.current.attendant = filter.attendants;
        }

        if (key === 'name') {
          selectedFilters.current.name = filter.name;
        }

        availableStatus.forEach((as) => {
          if (as.parameter != null) {
            Object.entries(as.parameter).forEach(([key1]) => {
              if (key === key1) {
                selectedFilters.current.status.push(as.id);
              }
            });
          }
        });

        availableTypeChat.forEach((atc) => {
          if (atc.parameter != null) {
            Object.entries(atc.parameter).forEach(([key2]) => {
              if (key === key2) {
                selectedFilters.current.type.push(atc.id);
              }
            });
          }
        });

        availableReadOptions.forEach((aro) => {
          if (aro.parameter != null) {
            Object.entries(aro.parameter).forEach(([key1]) => {
              if (key === key1) {
                selectedFilters.current.read.push(aro.id);
              }
            });
          }
        });

        if (idx === Object.keys(filter).length - 1) {
          setVerifyOpt(true);
        }
      });

      selectedFilters.current.order = filter.order;
      selectedFilters.current.page = filter.page;
    }
  }, [open]);

  useEffect(() => {
    if (verifyOpt) {
      if (selectedFilters.current.status.length === 0) {
        selectedFilters.current.status.push(1);
      }
      if (selectedFilters.current.type.length === 0) {
        selectedFilters.current.type.push(1);
      }
      if (selectedFilters.current.read.length === 0) {
        selectedFilters.current.read.push(1);
      }
    }
  }, [verifyOpt]);

  const handleToggleFilter = (filterType, filterId) => {
    const newSelectedFilters = { ...selectedFilters?.current };

    if (filterType === 'order') {
      newSelectedFilters[filterType] = filterId;
    } else {
      const isSelected = newSelectedFilters[filterType]?.includes(filterId);

      if (isSelected) {
        newSelectedFilters[filterType] = newSelectedFilters[filterType].filter(
          (id) => id !== filterId
        );
      } else {
        newSelectedFilters[filterType] = [
          ...(newSelectedFilters[filterType] || []),
          filterId,
        ];
      }

      if (
        filterType === 'type' ||
        filterType === 'status' ||
        filterType === 'read'
      ) {
        if (newSelectedFilters[filterType].length > 1) {
          newSelectedFilters[filterType] = newSelectedFilters[
            filterType
          ].filter((id) => id !== 1);
        } else if (newSelectedFilters[filterType].length === 0) {
          newSelectedFilters[filterType] = [1];
        }
      }
    }

    selectedFilters.current = newSelectedFilters;
    setForceRender((prev) => !prev);
  };

  const getParameters = (ids, available) => {
    return ids.reduce((acc, id) => {
      const aux = available.find((x) => x.id === id);
      if (aux.parameter) {
        const parameterKey = Object.keys(aux.parameter)[0];
        if (parameterKey !== 'parameter') {
          acc[parameterKey] = true;
        }
      }
      return acc;
    }, {});
  };

  const mapFilters = () => {
    const parametersType = getParameters(
      selectedFilters.current.type,
      availableTypeChat
    );
    const parametersStatus = getParameters(
      selectedFilters.current.status,
      availableStatus
    );
    const parametersRead = getParameters(
      selectedFilters.current.read,
      availableReadOptions
    );

    const filterRet = {
      page: 1,
      order: selectedFilters.current.order,
      ...parametersType,
      ...parametersStatus,
      ...parametersRead,
      idSector: selectedFilters.current.sector,
      attendants: selectedFilters.current.attendant,
      name: selectedFilters.current.name,
    };

    const filterStorage = { ...filterRet, name: '' };

    return [filterRet, filterStorage];
  };

  const applyFilters = () => {
    const filters = mapFilters();
    handleChangeFilter(filters[0]);
    localStorage.setItem(localStorageName, JSON.stringify(filters[1]));
  };

  const handleApplyFilters = () => {
    applyFilters();
    handleClose();
  };

  function renderFilterGroup(
    title,
    data,
    filterType,
    primaryField = 'description',
    idField = 'id'
  ) {
    if (filterType === 'sector' && !showSectorSection) {
      return <></>;
    }

    if (filterType === 'type' && !showTypeSection) {
      return <></>;
    }

    if (filterType === 'attendant' && attendants.length === 0) {
      return <></>;
    }

    const isSelectedFilter = (itemId) => {
      if (filterType === 'order') {
        return selectedFilters.current[filterType] === itemId;
      }
      return selectedFilters.current[filterType].includes(itemId);
    };

    return (
      <div className={classes.groupContainer} style={{ marginBottom: '30px' }}>
        <Typography variant="inherit">{title}</Typography>
        <List>
          {data?.map((item) => (
            <React.Fragment key={item[idField]}>
              <ListItem
                button
                onClick={() => handleToggleFilter(filterType, item[idField])}
                selected={isSelectedFilter(item[idField])}
                style={{
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  marginBottom: '5px',
                }}
              >
                <Checkbox
                  edge="start"
                  checked={isSelectedFilter(item[idField])}
                  tabIndex={-1}
                  disableRipple
                  color="primary"
                />
                <ListItemText primary={item[primaryField]} />
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </div>
    );
  }

  return (
    <div>
      <RoundedButton
        className={classes.customButtonDefault}
        onClick={handleOpen}
      >
        <IconButton>
          <FilterListIcon />
        </IconButton>
      </RoundedButton>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={isMobile ? 'xs' : 'md'}
      >
        <DialogTitle disableTypography>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">Filtros</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        {isLoading ? (
          <div className={classes.divLoading}>
            <CircularProgress color="primary" size={20} />
          </div>
        ) : (
          <>
            <DialogContent>
              {renderFilterGroup('Por setor', sectors, 'sector', 'Nome', 'Id')}
              {renderFilterGroup(
                'Por atendente',
                attendants,
                'attendant',
                'name',
                'id'
              )}
              {renderFilterGroup('Por tipo', availableTypeChat, 'type')}
              {renderFilterGroup(
                'Exibição por status',
                availableStatus,
                'status'
              )}
              {renderFilterGroup('Leitura', availableReadOptions, 'read')}
              {isMobile
                ? renderFilterGroup('Ordenação', availableSituations, 'order')
                : null}
            </DialogContent>

            <DialogActions>
              <Button
                onClick={handleApplyFilters}
                color="primary"
                variant="contained"
              >
                Aplicar Filtros
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};
