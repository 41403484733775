import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  IconButton,
  DialogContent,
  Typography,
  Toolbar,
  TableContainer,
  TablePagination,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
} from '@material-ui/core';
import {
  RoundedInput,
  TableRowFocusable,
} from '../../components';

import { Skeleton } from '@material-ui/lab';
import api from '../../services/api';
import useAlert from '../../hooks/useAlert';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
  container: {
    width: '800px',
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
  headerMain: {
    padding: '10px 15px 10px 25px'
  },
  buttons: {
    marginTop: '4em',
    textAlign: 'right',
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    fontSize: '16px',
  },
  input: {
    margin: '1.5em auto 1em 0',
    borderRadius: '5px !important',
    '& input + fieldset': {
      borderRadius: '5px !important',
    },
  },
  cellRecipients: {
    width: '60%',
  },
  cellSend: {
    textAlign: 'center',
    width: '20%',
  },
  cellVisualization: {
    textAlign: 'center',
    width: '20%',
  },
  divNameRecip: {
    display: 'flex',
    alignItems: 'center',
  },
  divNames: {
    display: 'flex',
    flexDirection: 'column',     
  },
  sonName: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '12px',
  }
}));

export default ({
  handleClose = () => {},
  open = false,
  idLote,
  idAssuntoMsgLote,
}) => {
  const classes = useStyles();
  const setAlert = useAlert();

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [text, setText] = useState('');
  const [filter, setFilter] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [recipientNumber, setRecipientNumber] = useState(0);
  const [numberNotViewed, setNumberNotViewed] = useState(0);
  const [numberViewed, setNumberViewed] = useState(0);


  const handleSendEnterPress = (text, e) => {
    if (e.key === 'Enter')
      handleSearch(text);
  };

  const handleSearch = (message) => {
    if (message === text)
      return;
  
    setPage(1);
    setText(message);
    setFilter({
      name: message,
    });
  };

  useEffect(() => {
    if (open) {
      requestRecipients(page);      
    }
  }, [open, filter]);

  useEffect(() => {
    if (open) {
      requestStatisticByMessage(page);      
    }
  }, [open]);

  const requestStatisticByMessage = async () => {
    const params = {
      idLote: idLote
    };

    api
      .get('chat/getStatisticByMessage', {
        params,
      })
      .then((response) => {
        setRecipientNumber(response.data.QtdDestinatarios);
        setNumberNotViewed(response.data.QtdMensagensNaoVisualizadas);
        setNumberViewed(response.data.QtdMensagensVisualizadas);
      })
      .catch(() => {
        setAlert({
          isOpen: true,
          type: 'error',
          message: 'Ocorreu um erro inesperado!',
        });
      });
  };

  const requestRecipients = async (pag) => {
    setIsLoading(true);

    const params = {
      page: pag, 
      limit: 5,
      idAssuntoMsgLote,
      idLote,
      ...filter,
    };
    api
      .get('chat/getRecipientsByMessage', {
        params,
      })
      .then((response) => {
        if (!response.data.Sucesso)
        handleErrorRecipients(response.data.Mensagem);
        else {
          setTotal(response.data.Dados.Total);
          setPage(response.data.Dados.Page);
          setRecipients([...response.data.Dados.Data]);
          setIsLoading(false);
        }
      })
      .catch(() => handleErrorRecipients(null));
  };

  const handleErrorRecipients = (message) => {
    setIsLoading(false);

    setAlert({
      isOpen: true,
      type: 'error',
      message: (message ?? 'Ocorreu um problema inesperado!'),
    });
    
    setRecipients([]);
    setPage(1);
    setTotal(0);
  };

  const handleChangePage = (event, nextPage) => requestRecipients(nextPage + 1);

  const handleFilterStatus = (idStatus) => {
    setPage(1);
    setFilter({
      status: idStatus,
    });
  };

  const handleCloseModal = () => {
    setFilter();
    setText('');
    handleClose();
  };

  return (
    <Dialog
      maxWidth={false}
      onClose={() => handleCloseModal()} 
      aria-labelledby="customized-dialog-title" 
      open={open}
    >
      <div className={classes.container}>
        <DialogActions className={classes.headerMain}>
          <Typography 
            component={'span'} 
            color="primary"
            style={{ marginRight: 'auto' }}
          >
            <strong>DETALHAMENTO DOS ENVIOS</strong>
          </Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={() => handleCloseModal()}>
            <Close />
          </IconButton>
        </DialogActions>
        <DialogContent
          style={{ padding: '5px 25px 25px 25px' }}
        >
          <div className={classes.status}>
            <div style={{cursor: 'pointer'}} onClick={() => handleFilterStatus(1)}>
              <span style={{color: '#4EB275'}}>Destinatários: </span>
              {recipientNumber}
            </div>
            <div style={{cursor: 'pointer'}} onClick={() => handleFilterStatus(2)}>
              <span style={{color: '#FB8C00'}}>Visualizados: </span>
              {numberViewed}
            </div>
            <div style={{cursor: 'pointer'}} onClick={() => handleFilterStatus(3)}>
              <span style={{color: '#FF5252'}}>Não visualizados: </span>
              {numberNotViewed}
            </div>
          </div>
          <Toolbar>
            <RoundedInput
              variant="outlined"
              placeholder="Pesquisar por destinatário"
              size="small"
              fullWidth
              className={classes.input}
              onBlur={(e) => handleSearch(e.target.value)}
              onKeyPress={(e) => handleSendEnterPress(e.target.value, e)}
            />
          </Toolbar>
          <TableContainer>
            <Table>
              <TableHead>
                <TableCell>Destinatários</TableCell>
                <TableCell style={{textAlign: 'center'}}>Enviado</TableCell>
                <TableCell style={{textAlign: 'center'}}>Visualizado</TableCell>
              </TableHead>
              <TableBody>
              {isLoading
                  ? new Array(10).fill().map((e, i) => i).map((e) => (
                      <TableRowFocusable key={e} onClick={() => {}} hover>
                        <TableCell>
                          <Skeleton animation="wave" />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" />
                        </TableCell>
                      </TableRowFocusable>
                    ))
                  : recipients?.map((recip) => (
                    <TableRow key={recip.IdMensagem}>
                      <TableCell className={classes.cellRecipients}>
                        <div className={classes.divNameRecip}>
                          <Avatar src={recip.Foto}>
                            {recip.Nome[0]?.toUpperCase()}
                          </Avatar>
                          <div className={classes.divNames} style={{marginLeft: '1em'}}>
                            <span>{recip.Nome}</span>
                            <span className={classes.sonName}>{recip.Papeis}</span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={classes.cellSend}>
                        {recip.DataEnvio != null ?                      
                          <span>{moment(recip.DataEnvio).format('DD/MM - HH:mm')}</span> :
                          <span>-</span>                               
                        }
                      </TableCell>
                      <TableCell className={classes.cellVisualization}>
                        {recip.DataVisualizacao != null ?                      
                          <span>{moment(recip.DataVisualizacao).format('DD/MM - HH:mm')}</span> :
                          <span>Não visualizado</span>                               
                        }
                      </TableCell>
                    </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={total}
            rowsPerPage={5}
            page={page - 1}
            onChangePage={handleChangePage}
          />
        </DialogContent>
      </div>
    </Dialog>
  );
};
