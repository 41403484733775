import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  IconButton,
  DialogContent,
  Typography,
  Grid,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  TextField,
  FormGroup,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Close, CheckCircle } from '@material-ui/icons';
import { Alert, Autocomplete } from '@material-ui/lab';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Recipient } from './components/Recipient';
import useDebounce from '../../hooks/useDebounce';
import { RoundedButton } from '../../components';
import useAlert from '../../hooks/useAlert';
import api from '../../services/api';
import SendMessageModal from './SendMessageModal';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    color: theme.palette.grey[500],
  },
  headerMain: {
    padding: '10px 15px 10px 25px',
  },
  buttons: {
    marginTop: '4em',
    textAlign: 'right',
  },
  loadingButton: {
    borderRadius: '5px !important',
  },
  alertCustom: {
    width: '100%',
  },
  overCustom: {
    maxHeight: '150px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: '#F5F5F5',
    },
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#D8D8D8',
      },
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3);',
      borderRadius: '3px',
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '3px',
      backgroundColor: '#E6E6E6',
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3);',
    },
  },
}));

export default ({ handleClose = () => {}, sectors = [], open = false }) => {
  const classes = useStyles();
  const debounce = useDebounce();
  const setAlert = useAlert();

  const [selectedSector, setSelectedSector] = useState(null);
  const [disableTargetField, setDisableTargetField] = useState(true);
  const [selectedTargets, setSelectedTargets] = useState([]);
  const [queryTargetAudiences, setQueryTargetAudiences] = useState('');
  const [targetAudiences, setTargetAudiences] = useState([]);
  const [loadingTarget, setLoadingTargert] = useState(false);
  const [selectedRecipients, setSelectedRecipients] = useState([]);

  const [subjectText, setSubjectText] = useState('');
  const [openSendMessageModal, setOpenSendMessageModal] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSetTextAudience = debounce((text) =>
    setQueryTargetAudiences(text)
  );
  const handleFilterText = (e) => {
    if (e == null) return;

    if (typeof e === 'string' || e instanceof String) handleSetTextAudience(e);
  };

  const searchTargetAudiences = (queryText) => {
    setLoadingTargert(true);

    const params = {
      page: 1,
      limit: 1000,
      sectorId: selectedSector,
      appRole: [
        'coordination',
        'communication',
        'completeConcierge',
        'admin',
        'sectorMessage',
      ],
    };

    if (queryText && queryText.length > 0) params.query = queryText;

    api
      .get('target-audience', {
        params,
      })
      .then((response) => {
        const dataTemp = [...response.data.data];
        setTargetAudiences(dataTemp);
        setLoadingTargert(false);
      })
      .catch(() => {
        setTargetAudiences([]);
        setLoadingTargert(false);
        setAlert({
          isOpen: true,
          type: 'error',
          message: 'Ocorreu um erro inesperado ao buscar público alvo',
        });
      });
  };

  useEffect(() => {
    if (open && (selectedSector != null || sectors.length === 1)) {
      setDisableTargetField(false);
      setSelectedTargets([]);
      searchTargetAudiences(queryTargetAudiences);
      if (sectors.length === 1) {
        setSelectedSector(sectors[0].Id);
      }
    }
    // eslint-disable-next-line
  }, [selectedSector, open]);

  useEffect(() => {
    if (open) {
      searchTargetAudiences(queryTargetAudiences);
    }
  }, [queryTargetAudiences, open]);
  const handleSelectTargets = (targets) => {
    const limitTarget = 10;
    if (targets.length > limitTarget) {
      setAlert({
        isOpen: true,
        type: 'warning',
        message: `Limite máximo de (${limitTarget}) públicos alvos atingido.`,
      });
      return;
    }
    setSelectedTargets(targets);
  };

  const handleSetTextSubject = debounce((text) => setSubjectText(text));

  const handleWarningConfirm = (message) => {
    setAlert({
      isOpen: true,
      type: 'warning',
      message,
    });
  };

  const getSelectedSector = () => {
    if (sectors && sectors.length === 1) return sectors[0].Id;

    return selectedSector;
  };

  const handleRequestConfirm = () => {
    const sectorTemp = getSelectedSector();

    if (sectorTemp == null) {
      handleWarningConfirm('Necessário selecionar um setor.');
      return;
    }

    const targets = selectedTargets;

    if (targets.length === 0) {
      handleWarningConfirm('Necessário selecionar um público-alvo.');
      return;
    }

    const subject = subjectText;

    if (subject === '') {
      handleWarningConfirm('Necessário preencher o campo assunto');
      return;
    }
    setOpenSendMessageModal(true);
  };

  const handleChangeSelectedRecipients = (recips) => {
    setSelectedRecipients(recips.filter((r) => r.CodigoPessoa));
  };

  const SectorSelection = () => {
    const handleChange = (sectorId) => {
      setSelectedSector((prevSelected) =>
        prevSelected === sectorId ? null : sectorId
      );
    };

    return (
      <List>
        <ListItem style={{ padding: '8px 0px' }}>
          <ListItemText
            primary={
              <Typography component="span" color="primary">
                <strong>Enviar como *</strong>
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={{ padding: '8px 0px' }}>
          <FormControl component="fieldset">
            <FormGroup style={{ flex: '1' }}>
              {sectors.map((s) => (
                <>
                  <ListItem
                    button
                    onClick={() => handleChange(s.Id)}
                    selected={selectedSector === s.Id}
                    style={{
                      border: '1px solid #ccc',
                      marginBottom: '2px',
                      padding: '8px 0px',
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={selectedSector === s.Id}
                        color="primary"
                      />
                    </ListItemIcon>
                    <ListItemText primary={s.Nome} />
                  </ListItem>
                </>
              ))}
            </FormGroup>
          </FormControl>
        </ListItem>
      </List>
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogActions className={classes.headerMain}>
        <Typography
          component="span"
          color="primary"
          style={{ marginRight: 'auto' }}
        >
          {isMobile ? (
            <strong>Nova conversa</strong>
          ) : (
            <strong>NOVA MENSAGEM</strong>
          )}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </DialogActions>
      <DialogContent
        style={{
          flex: isMobile ? '1' : '',
        }}
      >
        {isMobile ? (
          <SectorSelection />
        ) : (
          <Grid container>
            <Grid item md={2} style={{ paddingTop: '8px' }}>
              <Typography component="span" color="primary">
                <strong>Enviar como *</strong>
              </Typography>
            </Grid>
            <Grid item md={10} className={classes.overCustom}>
              {sectors && sectors.length > 1 ? (
                <FormControl component="fieldset">
                  <RadioGroup row defaultValue="top">
                    {sectors.map((s) => (
                      <FormControlLabel
                        key={s.Id}
                        value={s.Id}
                        checked={s.Id === parseInt(selectedSector)}
                        onChange={(e) => setSelectedSector(e.target.value)}
                        style={{ marginRight: '35px' }}
                        control={
                          <Radio
                            checkedIcon={<CheckCircle />}
                            color="primary"
                          />
                        }
                        label={s.Nome}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              ) : (
                <FormControl component="fieldset">
                  <RadioGroup row defaultValue="top">
                    <FormControlLabel
                      value={sectors[0]?.Id}
                      checked
                      disabled
                      control={
                        <Radio checkedIcon={<CheckCircle />} color="primary" />
                      }
                      label={sectors[0]?.Nome}
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </Grid>
          </Grid>
        )}
        <Grid
          container
          spacing={1}
          style={{ marginTop: '15px' }}
          direction={isMobile ? 'column' : 'row'}
        >
          <Grid item md={2} style={{ paddingTop: '8px' }}>
            <Typography component="span" color="primary">
              <strong>Público alvo *</strong>
            </Typography>
          </Grid>

          <Grid item md={10} style={{ maxWidth: '100%' }}>
            <Autocomplete
              multiple
              size="small"
              options={targetAudiences}
              value={selectedTargets}
              onInputChange={(e) => handleFilterText(e?.target?.value)}
              onChange={(_event, value) => handleSelectTargets(value)}
              getOptionSelected={(option, value) => {
                return option.id === value.id;
              }}
              getOptionLabel={(option) => option.descricao}
              loading={loadingTarget}
              onClose={() => handleFilterText('')}
              clearOnEscape
              disabled={disableTargetField}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Procurar" />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginTop: '15px' }}>
          <Grid item md={2} />
          <Grid item md={10}>
            <Alert className={classes.alertCustom} severity="info">
              Se somente o campo público-alvo for informado, serão considerados
              como destinatários todas as pessoas vinculadas ao público-alvo
              selecionado.
            </Alert>
          </Grid>
        </Grid>
        <Recipient.Selector
          selectedRecipients={selectedRecipients}
          handleChangeSelectedRecipients={handleChangeSelectedRecipients}
          selectedTargets={selectedTargets}
        />
        <Grid
          container
          spacing={1}
          style={{ marginTop: '15px' }}
          direction={isMobile ? 'column' : 'row'}
        >
          <Grid item md={2} style={{ paddingTop: '8px' }}>
            <Typography component="span" color="primary">
              <strong>Assunto *</strong>
            </Typography>
          </Grid>
          <Grid color="primary" item md={10}>
            <TextField
              variant="outlined"
              placeholder="Digite um assunto"
              onChange={(e) => handleSetTextSubject(e.target.value)}
              size="small"
              InputLabelProps={{}}
              fullWidth
              required
              inputProps={{ maxLength: 30 }}
            />
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          <RoundedButton
            className={classes.loadingButton}
            variant="contained"
            color="primary"
            onClick={handleRequestConfirm}
          >
            Iniciar chat
          </RoundedButton>
        </div>
        <SendMessageModal
          open={openSendMessageModal}
          handleClose={() => setOpenSendMessageModal(false)}
          selectedRecipients={selectedRecipients}
          selectedTargets={selectedTargets}
          subjectText={subjectText}
          idSector={selectedSector}
        />
      </DialogContent>
    </Dialog>
  );
};
