import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  CircularProgress,
} from '@material-ui/core';
import { AttachFile, Send } from '@material-ui/icons';
import ImageIcon from '@material-ui/icons/Image';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import Backdrop from '@material-ui/core/Backdrop';
import useAlert from '../../hooks/useAlert';

const useStyles = makeStyles((theme) => ({
  inputFile: {
    display: 'none',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '600px',
    height: '600px',
    maxWidth: '90%',
    maxHeight: '90%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
  },
  imgModal: {
    flex: 1,
    width: '100%',
    height: '50%',
    objectFit: 'contain',
  },
  iconModal: {
    width: '100%',
    textAlign: 'right',
  },
  anexo: {
    display: 'inline',
  },
  attachIcon: {
    margin: '12px',
  },
}));

export default ({
  setFile = () => {},
  setMimeType = () => {},
  limit = 5,
  setBase64 = () => {},
  onConfirmFile = () => {},
  isSend = false,
}) => {
  const classes = useStyles();
  const [anchorElAttach, setAnchorElAttach] = useState(false);
  const [fileType, setFileType] = useState('');
  const [fileBlobUrl, setFileBlobUrl] = useState('');
  const [open, setOpen] = useState(false);
  const setAlert = useAlert();
  const [loadingFile, setLoadingFile] = useState(false);

  const handleClickAttach = (event) => {
    setAnchorElAttach(event.currentTarget);
  };

  const handleCloseAttach = () => {
    setAnchorElAttach(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setAnchorElAttach(false);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorElAttach(false);
    setFile('');
    setMimeType('');
    setFileType('');
    setBase64('');
    setFileBlobUrl('');
    setLoadingFile(false);
  };

  const toBase64 = (newFile) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(newFile);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const openModal = (event) => {
    if (event.target.files.length > 0) {
      if (
        event.target.files[0].type.includes('application/pdf') ||
        event.target.files[0].type.includes('image')
      ) {
        setLoadingFile(true);
        const file = event.target.files[0];
        if (file.size > limit * 1000000) {
          setAlert({
            isOpen: true,
            type: 'error',
            message: 'O tamanho do arquivo excede o limite de 5MB.',
          });
          event.target.value = null;
          return;
        }
        setFile(file);
        setMimeType(file.type);
        setFileType(file.type);
        const url = URL.createObjectURL(file);
        setFileBlobUrl(url);
        toBase64(file)
          .then((b64) => {
            setBase64(b64);
          })
          .finally(() => {
            setLoadingFile(false);
          });
        handleOpen();
      } else {
        setAlert({
          isOpen: true,
          type: 'error',
          message: 'Formato do arquivo não suportado.',
        });
      }
      event.target.value = null;
    }
  };

  const sendFile = () => {
    setOpen(false);
    setAnchorElAttach(false);
    onConfirmFile();
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.iconModal}>
              <IconButton onClick={handleClose} color="primary">
                <CloseIcon fontSize="large" />
              </IconButton>
            </div>
            {loadingFile && <CircularProgress />}
            {!loadingFile &&
              fileBlobUrl.length > 0 &&
              (fileType.includes('image') ? (
                <img
                  src={fileBlobUrl}
                  className={classes.imgModal}
                  alt="Preview da imagem"
                />
              ) : (
                <object
                  data={fileBlobUrl}
                  type={fileType}
                  width="100%"
                  height="100%"
                  aria-label="Preview do documento"
                />
              ))}
            <div className={classes.iconModal}>
              <IconButton onClick={sendFile} color="primary">
                {isSend ? <Send fontSize="large" /> : <span>Ok</span>}
              </IconButton>
            </div>
          </div>
        </Fade>
      </Modal>
      <div className={classes.anexo}>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClickAttach}
        >
          <AttachFile className={classes.attachIcon} color="primary" />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorElAttach}
          keepMounted
          open={Boolean(anchorElAttach)}
          onClose={handleCloseAttach}
          className={classes.archorAttach}
        >
          <MenuItem component="label" htmlFor="imagem">
            <ImageIcon />
            Imagem
            <input
              type="file"
              accept="image/jpeg,
                        image/png,
                        image/gif"
              name="imagem"
              id="imagem"
              className={classes.inputFile}
              onChange={openModal}
            />
          </MenuItem>
          <MenuItem component="label" htmlFor="arquivo">
            <DescriptionOutlinedIcon />
            Arquivo
            <input
              type="file"
              accept="application/pdf"
              name="arquivo"
              id="arquivo"
              className={classes.inputFile}
              onChange={openModal}
            />
          </MenuItem>
        </Menu>
      </div>
    </>
  );
};
